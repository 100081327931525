<template>
  <v-card>
    <v-card-title class="justify-center">
      
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
      hide-default-footer
    ></v-data-table>

    <v-pagination
        v-model="page"
        :length="pages"
        v-on:input="fetchData()"
      ></v-pagination>

  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      url : process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "Inicio", align: "start", value: "fechaInicio" },
        { text: "Fin", value: "fechaFin" },
        { text: "Supervisor", value: "supervisor" },
        { text: "Productor", value: "productor" },
        { text: "Linea", value: "lineaNegocio" },
        { text: "Producto", value: "producto" },
        { text: "Detalle", value: "detalleProducto" },
        { text: "Tipo", value: "tipoNegocio" },
        { text: "Poliza", value: "poliza" },
        { text: "AseguradoP", value: "aseguradoPrincipal" },
        { text: "modo", value: "modo" },
        { text: "Prima Anual", value: "primaAnual" },
        { text: "FechaApliPag", value: "fechaAplicacionPago" },
        { text: "Importe", value: "importaPago" },
        { text: "Canal", value: "canalPago" },
        { text: "comision", value: "comision" },
        { text: "fechaPagoComision", value: "fechaPagoComision" },
        { text: "orden#", value: "ordenPago" },
      ]
    };
  },
  methods: {
    fetchData() {
      console.log('llamando api')
      //axios.get("data/datos.json").then(response => {
      axios.get(this.url+'/comisiones-reporte/paginado?page='+this.page+'&limit=10').then(response => {
        this.datos = response.data?.items || [];
        this.pages = response.data?.meta?.totalPages || 0;
      });
    },

  },
  created() {
    this.fetchData();
  }
};
</script>
