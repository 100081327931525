<template>
  <v-card>
    <v-card-title class="justify-center"> </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
      hide-default-footer
    ></v-data-table>

    <v-pagination
      v-model="page"
      :length="pages"
      v-on:input="fetchData()"
    ></v-pagination>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "Nivel", align: "start", value: "nivel" },
        { text: "Plan de Salud", value: "plan_de_salud" },
        { text: "1er Año", value: "first_year_commission" },
        { text: "2do 10 Años", value: "renov_2nd_10_years" },
        { text: "Otros", value: "service_fees_2_nd_and_each_succeding_year" },
        { text: "Total Renewals", value: "total_renewals" }
      ]
    };
  },
  methods: {
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/comisiones-salud/paginado?page=" +
            this.page +
            "&limit=10"
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    }
  },
  created() {
    this.fetchData();
  }
};
</script>
